.App {

}

.home-content {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dddddd;
}

.separator:not(:empty)::after {
  margin-left: .75em;
}


.apply-shadow {
  box-shadow: 0 0 10px rgb(0,0,0);
  -webkit-box-shadow: 0 0 10px rgb(0,0,0);
}

.markdown-content p, a, li {
  font-size: 12px;
}

.chakra-ui-dark .markdown-content a {
  color: cyan;
}

.markdown-content a {
  color: blue;
}

.markdown-content li {
  margin-top: 10px;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0)
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 1px solid darkgray;
}

/* Tooltip container */
.tooltip {
  /* position: relative; */
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  display: contents;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;
  z-index: 10;
  width: 300px;
  margin-top: -320px;
  margin-left: -360px

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  overflow: visible;
}

.expand-button {
  display: none;
}

.expanded-nav-container {
  display: none
}

.nav-button {
  justify-content: center;
}

@media screen and (max-width: 916px) {
  .nav-button-container:not(:first-child) {
    display: none;
  }

  .nav-button-container:first-child {
    width: calc(100vw - 80px);
  }
  
  .nav-button {
    justify-content: left;
  }

  .expanded-nav-container {
    display: block;
    transition: height 0.5s linear;
    height: 0;
    overflow: hidden
  }

  .expand-button {
    display: flex;
  }
}

.secondary-panel {
  padding: 32px 32px 32px 32px;
  @media screen and (max-width: 768px) {
    padding: 32px 20px 32px 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 32px 12px 32px 12px;
  }
}

.general-backdrop {
  margin-top: 140px;
  padding: 40px;
  @media screen and (max-width: 768px) {
    margin-top: 140px;
    padding: 32px;
  }
  @media screen and (max-width: 512px) {
    margin-top: 140px;
    padding: 20px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 140px;
    padding: 12px;
  }
}

.article {
  @media screen {
    margin: 80px 120px 80px 120px;
    * {
      line-height: 2.5em;
    }
    h1 {
      font-size: 3em;
      line-height: 3.5em;
    }
    h2 {
      font-size: 2.5em;
      line-height: 3em;
    }
    h3 {
      font-size: 2em;
      line-height: 2.5em;
    }
    h4 {
      font-size: 1.5em;
    }
    h5 {
      font-size: 1.17em;
    }
    h6 {
      font-size: 1em;
    }
    p, code {
      font-size: 1em;
    }
    ul, ol {
      margin-left: 2em;
      font-size: 1em;
    }
  }
  @media screen and (max-width: 1200px) {
    margin: 80px 80px 80px 80px;
  }
  @media screen and (max-width: 768px) {
    margin: 64px 40px 64px 40px;
    * {
      line-height: 2em;
    }
    h1 {
      font-size: 3em;
      line-height: 3em;
    }
    h2 {
      font-size: 2.5em;
      line-height: 2.5em;
    }
    h3 {
      font-size: 2em;
      line-height: 2em;
    }
    h4 {
      font-size: 1.5em;
    }
    h5 {
      font-size: 1.17em;
    }
    h6 {
      font-size: 1em;
    }
    p, code {
      font-size: 1em;
    }
    ul, ol {
      margin-left: 1.5em;
      font-size: 1em;
    }
  }
  @media screen and (max-width: 480px) {
    margin: 48px 24px 48px 24px;
  }
  /* revert all element to default */
}
.slider-thumb::before {
  position: fixed;
  content: "";
  left: calc(30% + var(--offset-x, 0px)); /* Adjust based on custom property */
  top: calc(20% + var(--offset-y, 0px)); /* Adjust based on custom property */
  width: 450px;
  height: 450px;
  background: var(--blob-color, '#22000022');
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  animation: sliderShape 5s linear infinite;
  display: block;
  z-index: -1;
  overflow: hidden;
}
/* 
.slider-thumb[data-color-mode="dark"] {
  background: #dddddd22;
}

.slider-thumb[data-color-mode="light"] {
  background: #22222222;
} */

/* Define multiple keyframes for variation if needed */


@keyframes sliderShape {
  0%, 100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  /* Add more keyframes as needed */
}

/* Example of applying custom properties for positioning */
.slider-thumb:nth-child(1)::before {
  --offset-x: -50%;
  --offset-y: -15%;
}

.slider-thumb:nth-child(2)::before {
  --offset-x: 50%; /* Adjust positioning */
  --offset-y: 40%; /* Adjust positioning */
  width: 750px;
  height: 750px;
}

.slider-thumb:nth-child(3)::before {
  --offset-x: 20%; /* Adjust positioning */
  --offset-y: 20%; /* Adjust positioning */
  width: 350px;
  height: 350px;
}

.slider-thumb:nth-child(4)::before {
  --offset-x: -15%; /* Adjust positioning */
  --offset-y: 42%; /* Adjust positioning */
  width: 250px;
  height: 250px;
}